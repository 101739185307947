// preact
import { Router } from 'preact-router'
import { useEffect } from 'preact/hooks'

// styles
import './app.scss'

// stores
import SelfStore from 'stores/self'

// components
import Button from 'components/Button'
import Footer from 'components/Footer'

// pages
import Login from 'pages/Login'
import Dashboard from 'pages/Dashboard'


const App = () => {
	const selfStore = SelfStore.use()

	useEffect(() => {
		selfStore.self(true)
	}, [])

	return selfStore.selfing ? <p>Loading...</p> : !selfStore.user ? <Login/> : <>
		<Router>
			<Dashboard path="/" default/>
		</Router>
		<Footer>
			<Button to={''}>Database home</Button>
		</Footer>
	</>
}

export default App
