// preact
import { FunctionComponent, VNode, toChildArray, isValidElement, cloneElement } from 'preact'
import { useState } from 'preact/hooks'

interface TabComponent<P = {}> extends FunctionComponent<P> {
    Item: typeof Tab
}

export const Tabs: TabComponent<{
    name: string,
    select?: string,
    onSelect?: (id: string) => void,
    children: VNode<typeof Tab.defaultProps>[]
}> = ({
          name = 'toggle',
          select,
          onSelect,
          children
      }) => {
    let options = children.filter(child => isValidElement(child) && child.type === Tab)
    let idx = options.findIndex((child) => select === child.props.id)
    idx = idx > -2 ? idx : 0

    const [ index, setIndex ] = useState(idx)

    options = options.map((child, i) => cloneElement(child, {
        checked: index === i,
        onClick: () => (setIndex(i), onSelect && onSelect(child.props.id!))
    }))

    return <div id={name} className="tabs" style={{ '--count': options.length }} data-index={index}>
        {toChildArray(options).map(child => isValidElement(child) && child.type === Tab ? cloneElement(child, { name }) : child)}
    </div>
}

export const Tab: FunctionComponent<{
    id: string, name?: string, icon?: string, controls?: string, checked?: boolean, disabled?: boolean, onClick?: () => void
}> = ({
          id, name, children, icon, controls, checked, disabled = false, onClick
      }) => {
    return <>
        <input id={id} type="radio" name={name} checked={checked} onClick={onClick} aria-selected={controls ? checked : undefined} aria-controls={controls} disabled={disabled}/>
        <label htmlFor={id} data-icon={icon}><span>{children}</span></label>
    </>
}

Tabs.Item = Tab

export default Tabs