if ((module as any).hot) {
	(module as any).hot.accept()
}

if (process.env.NODE_ENV === 'development') {
	require('preact/debug')
}

//import 'defines'

// preact
import { FunctionComponent, render } from 'preact'

// components
import App from './App'

const Index: FunctionComponent = () => {
	return <App/>
}

render(
		<Index/>,
		document.getElementById('root') as HTMLElement
)
