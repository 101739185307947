// preact
import { FunctionComponent } from 'preact'
import { useState } from 'preact/hooks'

// stores
import SelfStore from 'stores/self'

// components
import Page from 'components/Page'
import Header from 'components/Header'
import Button from 'components/Button'
import Input from 'components/Input'
import Message from 'components/Message'


const Login: FunctionComponent = () => {
	const selfStore = SelfStore.use()

	const [ email, setEmail ] = useState('')
	const [ password, setPassword ] = useState('')

	const onSubmit = (e: Event) => {
		e.preventDefault()
		selfStore.logIn({ email, password, admin: true })
	}

	return <>
		<Header>
			<Button to="/">Database</Button>
			<Button to={'https://www.rottencityculturaldistrict.com/'}>About</Button>
		</Header>
		<Page name="Login">
			<h2 className="auth-title">Log in</h2>
			<form id="auth-form" name="auth" onSubmit={e => (e.preventDefault(), false)}>
				<Input type="email" name="email" label="Email address" placeholder="youremail@example.com" autocomplete="email"
					   value={email} onInput={setEmail}/>
				<Input type="password" name="password" label="Password" autocomplete="current-password"
					   value={password} onInput={setPassword}/>
				<footer className="signup-footer">
					<Button importance="primary" type="submit" disabled={selfStore.loading} onClick={onSubmit}>Log in</Button>
				</footer>
				<Message type="error">{selfStore.errors?.errors?.error}</Message>
			</form>
		</Page>
	</>
}

export default Login
