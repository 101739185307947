import {FunctionComponent} from "preact";
import Select from "components/Select";
import {useState} from "preact/compat";

const b1 = Math.floor(Math.random() * 20)
const b2 = Math.floor(Math.random() * 20)
const b3 = Math.floor(Math.random() * 20)
const b4 = Math.floor(Math.random() * 20)

const Filters: FunctionComponent<{
    onSelect?: () => void, view?: 'creatives' | 'partners', id?: string
}> = ({
          onSelect,
          view,
          id,
          children
      }) => {


    return <section className="list-filters" style={{
        '--b1': `var(--${b1})`,
        '--b2': `var(--${b2})`,
        '--b3': `var(--${b3})`,
        '--b4': `var(--${b4})`
    }}>
        <h3 className="list-title">List Filters</h3>
        {children}
    </section>
}

export default Filters;